import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const TopBarMain = (props) => {
    const nav = useNavigate();
  return (
    <div className='row bg-light shadow  p-3' 
        style={{
            width: 'inherit'
        }} 
    >   
        {
            props.back?
            <div className='col-1 text-left' onClick={() => nav(-1) }>
               <i className='fa-solid fa-arrow-left'></i>
            </div>
            :
            <></>
        }
            <div className='col text-left '>
                <span className='nav-link fw-bold'>{props.title}</span>
            </div>
    </div>
  )
}

export default TopBarMain