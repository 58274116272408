import React, { createContext, useState } from "react";
import secureLocalStorage from "react-secure-storage";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const cekLogin = () => {
    const isLogin = secureLocalStorage.getItem('isAuthenticated');

    return isLogin;
  };

  const login = () => {
    setIsAuthenticated(true);
    secureLocalStorage.setItem('isAuthenticated', true);
  };

  const logout = () => {
    setIsAuthenticated(false);
    secureLocalStorage.setItem('isAuthenticated', false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, cekLogin }}>
      {children}
    </AuthContext.Provider>
  );
};
