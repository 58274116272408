import React, { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { Link } from 'react-router-dom';

const NotFound = () => {
    const { isAuthenticated, cekLogin } = useContext(AuthContext);
  return (
    <div className='text-center row align-items-center justify-content-center' style={{ height: '100%'}}>
        <div>
            <p> 404 | Not Found</p>
            {
                cekLogin()?
                <Link to="/beranda" className='mt-5'>Back</Link>
                :
                <Link to="/" className='mt-5'>Back</Link>
            }
            </div>
    </div>
  )
}

export default NotFound