import React from 'react'
import { Link } from 'react-router-dom'

const BottomBarMain = () => {
  return (
    <div className='row bg-light shadow justify-content-between p-3 w-100 sticky-bottom' 
        style={{
            position: 'absolute',
            bottom: 0,
        }}
    >
        <div className='col text-center'>
            <Link className='nav-link' to={'/beranda'}>
                <i className='fa-solid fa-home'></i>
                <div>Beranda</div>
            </Link>
        </div>
        <div className='col text-center '>
            <Link className='nav-link' to={'/riwayat'}>
                <i className='fa-solid fa-history'></i>
                <div>Riwayat</div>
            </Link>
        </div>
        <div className='col text-center '>
            <Link className='nav-link' to={'/info'}>
                <i className='fa-solid fa-phone'></i>
                <div>Info</div>
            </Link>
        </div>
        <div className='col text-center '>
            <Link className='nav-link' to={'/pengaturan'}>
                <i className='fa-solid fa-cogs'></i>
                <div>Pengaturan</div>
            </Link>
        </div>
    </div>
  )
}

export default BottomBarMain