import React from 'react';
import logo from './logo.svg';
import './App.css';
import Routes from './Routes';
import MobilLayout from './layouts/MobilLayout';
import { AuthProvider } from './contexts/AuthContext';

function App() {
  return (
    <>
      <AuthProvider>
        <MobilLayout>
          <Routes />
        </MobilLayout>
      </AuthProvider>
    </>
  );
}

export default App;
