import React from 'react'
import BottomBarMain from '../components/BottomBarMain'
import TopBarMain from '../components/TopBarMain'
import Carousel from '../components/beranda/Carousel'
import Layanan from '../components/beranda/Layanan'

const Beranda = () => {
  return (
    <div>
        <TopBarMain title="Beranda" />
        <div className='mt-3'>
          <Carousel />
        </div>
        <div className='container'>
          <div className='mt-3'>
            <h1 className='h6'>Layanan</h1>
            <Layanan />
          </div>
        </div>
        <BottomBarMain />
    </div>
  )
}

export default Beranda