import React, { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { Link } from 'react-router-dom';

const LoadingScreen = () => {
    const { isAuthenticated } = useContext(AuthContext);
  return (
    <div className='text-center row align-items-center justify-content-center' style={{ height: '100%'}}>
        <lord-icon src="https://cdn.lordicon.com/pxruxqrv.json" trigger="loop-" delay={1000} style={{width: '80', height: '80'}}>
        </lord-icon>
    </div>
  )
}

export default LoadingScreen