import React, { useContext } from 'react'
import { BrowserRouter, Route, Routes as Routex } from 'react-router-dom'
import Login from './pages/Login'
import Register from './pages/Register'
import Beranda from './pages/Beranda'
import Riwayat from './pages/Riwayat'
import Info from './pages/Info'
import Pengaturan from './pages/Pengaturan'
import PublicRoute from './routes/PublicRoute'
import MemberRoute from './routes/MemberRoute'
import { AuthContext } from './contexts/AuthContext'

const Routes = () => {
  const { isAuthenticated, login, logout, cekLogin } = useContext(AuthContext);
  return (
    <BrowserRouter>
        <Routex>
          {
            cekLogin()?
            <>
              <Route path='/*' element={ <MemberRoute /> } />
            </>
            :
            <>
              <Route path='/*' element={ <PublicRoute /> } />
            </>
          }
            {/* <Route path='/login' element={ <Login /> } />
            <Route path='/register' element={ <Register /> } />
            <Route path='/beranda' element={ <Beranda /> } />
            <Route path='/riwayat' element={ <Riwayat /> } />
            <Route path='/info' element={ <Info /> } />
            <Route path='/pengaturan' element={ <Pengaturan /> } /> */}
        </Routex>
    </BrowserRouter>
  )
}

export default Routes