import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from '../pages/Login'
import Register from '../pages/Register'
import NotFound from '../pages/NotFound'

const PublicRoute = () => {
  return (
    <Routes>
        <Route path='*' element={ <NotFound />} />
        <Route path='/' element={ <Login />} />
        <Route path='/login' element={ <Login />} />
        <Route path='/register' element={ <Register />} />
    </Routes>
  )
}

export default PublicRoute