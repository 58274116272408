import React from 'react'
import TopBarMain from '../components/TopBarMain'
import BottomBarMain from '../components/BottomBarMain'
import { AppVersionx } from '../helper/Contants'

const Pengaturan = () => {
  return (
    <div>
        <TopBarMain title="Pengaturan" />
        <div className='text-center'>Versi {AppVersionx}</div>
        <BottomBarMain />
    </div>
  )
}

export default Pengaturan