import React from 'react'

const MobilLayout = (props) => {
  return (
    <div className='container'>
        <div className='row justify-content-center' style={{ height: '100vh'}}>
            <div className='card bg-light' style={{ maxWidth: 425,}}>
                {props.children}
            </div>
        </div>
    </div>
  )
}

export default MobilLayout