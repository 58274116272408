import React from 'react'
import TopBarMain from '../components/TopBarMain'
import BottomBarMain from '../components/BottomBarMain'

const Riwayat = () => {
  return (
    <div>
        <TopBarMain title="Riwayat" />
        <div>Riwayat</div>
        <BottomBarMain />
    </div>
  )
}

export default Riwayat