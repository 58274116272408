import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Riwayat from '../pages/Riwayat'
import Beranda from '../pages/Beranda'
import Pengaturan from '../pages/Pengaturan'
import NotFound from '../pages/NotFound'

const MemberRoute = () => {
  return (
    <Routes>
        <Route path='*' element={ <NotFound />} />
        <Route path='/beranda' element={ <Beranda />} />
        <Route path='/riwayat' element={ <Riwayat />} />
        <Route path='/pengaturan' element={ <Pengaturan />} />
    </Routes>
  )
}

export default MemberRoute