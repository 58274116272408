import React from 'react'
import { AppName } from '../helper/Contants'
import { Link } from 'react-router-dom'
    
const Register = () => {
  return (
    <div style={{ 
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    }}>
        <div className='d-block'>
            <h1 className='h3 text-primary text-center'>{AppName}</h1>
            <div className='form-group mb-3'>
                <label className='text-muted'>Nama Lengkap</label>
                <input 
                    placeholder='Nama Lengkap'
                    className='form-control input-app'
                    type='text'
                />
            </div> 
            <div className='form-group mb-3'>
                <label className='text-muted'>No. Telepon</label>
                <input 
                    placeholder='Nomor Telepon'
                    className='form-control input-app'
                    type='text'
                />
            </div>  
            <div className='form-group mb-3'>
                <label className='text-muted'>Email</label>
                <input 
                    placeholder='Email address'
                    className='form-control input-app'
                    type='email'
                />
            </div> 
            <div className='form-group mb-3'>
                <label className='text-muted'>Password</label>
                <input 
                    placeholder='Password'
                    className='form-control input-app'
                    type='password'
                />
            </div>  
            <div className='form-group mb-3'>
                <button type='button' className='w-100 btn btn-primary'>DAFTAR</button>
            </div>
            <div className='form-group mb-3'>
                <p>Sudah punya akun? <Link to={'/login'}>Masuk disini</Link> </p>
            </div>        

        </div>
    </div>
  )
}

export default Register