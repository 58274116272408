import React, { useContext, useState } from 'react'
import { AppName } from '../helper/Contants'
import { Link } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'
import LoadingScreen from './LoadingScreen'
    
const Login = () => {
    const { login } = useContext(AuthContext);
    const [loading_screen, set_loading_screen] = useState(false);

    const handleLogin = () => {
        set_loading_screen(true);
        setTimeout(() => {
            login();
            window.location.href = "/beranda";
            set_loading_screen(false);
        }, 3000);
    }

    if ( loading_screen ) {
        return (
            <LoadingScreen />
        )
    }
  return (
    <div style={{ 
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    }}>
        <div className='d-block'>
            <h1 className='h3 text-primary text-center'>{AppName}</h1>
            <div className='form-group mb-3'>
                <label className='text-muted'>Email</label>
                <input 
                    placeholder='Email address'
                    className='form-control input-app'
                    type='email'
                />
            </div> 
            <div className='form-group mb-3'>
                <label className='text-muted'>Password</label>
                <input 
                    placeholder='Password'
                    className='form-control input-app'
                    type='password'
                />
            </div>  
            <div className='form-group mb-3'>
                <button type='button' className='w-100 btn btn-primary' onClick={handleLogin}>MASUK</button>
            </div>
            <div className='form-group mb-3'>
                <p>Belum punya akun? <Link to={'/register'}>Daftar disini</Link> </p>
            </div>        

        </div>
    </div>
  )
}

export default Login