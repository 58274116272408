import React from 'react'

const Layanan = () => {
  return (
    <div className='row mt-3'>
        <div className='col-4 text-center'>
            <lord-icon src="https://cdn.lordicon.com/xhbsnkyp.json" style={{width: '80px', height: '80px'}}
                trigger="loop-on-hover"
                delay="1000"
            >
            </lord-icon>
            <small className='fw-bold'>Delivery</small>
        </div>
        <div className='col-4 text-center'>
            <lord-icon src="https://cdn.lordicon.com/sxindpsf.json"  style={{width: '80px', height: '80px'}}
                trigger="loop-on-hover"
                delay="1000"
            >
            </lord-icon>
            <small className='fw-bold'>Clean</small>
        </div>
        


    </div>
  )
}

export default Layanan